import { WORD_LENGTH, Feedback, LetterStatus } from "./game/game";

function GameTile(key: number, letter: string, status: LetterStatus) {
    return <div key={key} className="GameTile" data-status={status}>{letter}</div>;
}

function GameRow(props: { letters: string, feedback: Feedback | null, guessInvalid?: boolean }) {
    const { letters, feedback, guessInvalid } = props;

    const indices = [...Array(WORD_LENGTH).keys()];
    return <div className="GameRow" data-guessinvalid={guessInvalid ?? false}>
        {
            indices.map((idx) => {
                if (idx < letters.length) {
                    let status: LetterStatus;
                    if (!!feedback) {
                        status = feedback[idx];
                    } else {
                        status = LetterStatus.TBD;
                    }
                    return GameTile(idx, letters[idx], status);
                } else {
                    return GameTile(idx, "", LetterStatus.TBD);
                }
            })
        }
    </div>;
}

export default GameRow;
type Solution = {
    word: string,
    reason: string
}

const solutions: Solution[] = [
    { word: "spain", reason: "bc ur in spain! i miss u tho :(" },
    { word: "smoke", reason: "u r smokin' :)" },
    { word: "model", reason: "ur a model (also ROLE MODEL)" },
    { word: "forks", reason: "our next travel destination, obvs" },
    { word: "jacob", reason: "i think i'm team jacob but i haven't watched new moon yet" },
    { word: "hikes", reason: "...your least favorite kind of date" },
    { word: "socks", reason: "i think we should own a black cat with white little socks" },
    { word: "cycle", reason: "u said you'd take a spin class with me" },
    { word: "bossa", reason: "billie bossa nova is on my playlist" },
    { word: "arose", reason: "wordle in 1 = free dick whenever you want" },
    { word: "plane", reason: "i wanna fly you out to seattle ;)" },
    { word: "shane", reason: "i am a shaniac" },
];

export type { Solution };
export { solutions };

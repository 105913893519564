import { Solution, solutions } from "./solutions";
import { validGuesses } from "./validGuesses";

export const MAX_GUESSES: number = 6;
export const WORD_LENGTH: number = 5;

// create map of all solution words
const solutionWords: string[] = solutions.map(x => x.word);

/**
 * Game status.
 */
enum GameStatus {
    Playing,
    Won,
    Lost
}

/**
 * Current game state. Contains all state required to commit/restore a game to localStorage
 */
type GameState = {
    /**
     * Whether we are currently playing, have won, or lost
     */
    status: GameStatus;
    /**
     * The solution to the current puzzle
     */
    solution: Solution;
    /**
     * Guesses made towards the current solution.
     */
    guesses: string[],
    /**
     * Date this game state is from
     */
    timestamp: Date
};

/**
 * Makes a new game.
 * 
 * @returns A new game state.
 */
function initGame(): GameState {
    // helper function to convert dates to UTC
    // thanks https://stackoverflow.com/questions/542938/how-to-calculate-number-of-days-between-two-dates
    const treatAsUTC: (d: Date) => Date = (date: Date) => {
        var result = new Date(date);
        result.setMinutes(result.getMinutes() - result.getTimezoneOffset());
        return result;
    };

    const START_DATE = new Date(2022, 4, 27);
    const todayDate = new Date();
    todayDate.setHours(0, 0, 0, 0);

    // need to account for timezones, since START_DATE and todayDate can actually be affected by daylight savings.
    const puzzleIdx = Math.floor(treatAsUTC(todayDate).getTime() - treatAsUTC(START_DATE).getTime()) / (1000 * 60 * 60 * 24);

    return {
        status: GameStatus.Playing,
        solution: solutions[puzzleIdx],
        guesses: [],
        timestamp: todayDate
    };
}

/**
 * Makes a guess against a GameState and returns results.
 * @param game Current GameState.
 * @param guess Guess to make.
 * @returns GameState after this guess, or null if the guess was not valid.
 */
function makeGuess(game: GameState, guess: string): GameState | null {
    guess = guess.toLowerCase();
    // is the guess valid,
    // and is the current game in the Playing state?
    if ((solutionWords.indexOf(guess) === -1 && validGuesses.indexOf(guess) === -1) || game.status !== GameStatus.Playing) {
        // no? return null
        return null;
    }

    // copy game state
    let newState = { ...game }; // shallow copy, so newState.guesses is the same reference
    // copy game state's guesses into new array
    newState.guesses = [...game.guesses];

    // nb: no strict reeason we can't mutate the game state, but i thought it was cleaner to have immutable state
    // ...albeit negligibly less performant

    newState.guesses.push(guess);

    // did we guess the word exactly?
    if (guess === game.solution.word) {
        // set new state's status to won
        newState.status = GameStatus.Won;
    } else if (newState.guesses.length === MAX_GUESSES) {
        // did we run out of guesses?
        // set the new state's status to lost
        newState.status = GameStatus.Lost;
    }

    return newState;
}


enum LetterStatus {
    Green = "green",
    Yellow = "yellow",
    Gray = "gray",
    TBD = "tbd"
};
type Feedback = LetterStatus[];

/**
 * Gets feedback for a given word against a given solution.
 * @param solutionWord The solution word.
 * @param guessWord The guess word.
 */
function getFeedback(solutionWord: string, guessWord: string): Feedback {
    // count letters in solution word

    // create map from letter -> count
    let letterCounts: Map<string, number> = new Map<string, number>();
    for (const letter of [...solutionWord]) {
        // increment count of this letter by 1
        letterCounts.set(letter, (letterCounts.get(letter) ?? 0) + 1);
    }

    let feedback = new Array(WORD_LENGTH).fill(LetterStatus.Gray);

    // mark green
    for (const [index, letter] of [...guessWord].entries()) {
        const count = letterCounts.get(letter) ?? 0;

        if (solutionWord.charAt(index) === letter) {
            feedback[index] = LetterStatus.Green;
            letterCounts.set(letter, count - 1);
        }
    }

    // mark yellow
    for (const [index, letter] of guessWord.split("").entries()) {
        const count = letterCounts.get(letter) ?? 0;

        // make sure we haven't marked this spot green
        if (count > 0 && feedback[index] !== LetterStatus.Green) {
            feedback[index] = LetterStatus.Yellow;
            letterCounts.set(letter, count - 1);
        }
    }

    return feedback;
}

export type { GameState, Feedback };
export { initGame, makeGuess, getFeedback, LetterStatus, GameStatus };
import { GameState, initGame } from "./game/game";

function saveState(state: GameState) {
    localStorage.setItem("gameState", JSON.stringify(state));
}

function restoreState(): GameState {
    const gameStateString = localStorage.getItem("gameState");
    if (!!gameStateString) {
        const state: GameState = JSON.parse(gameStateString);
        state.timestamp = new Date(state.timestamp);

        if (state.timestamp.setHours(0, 0, 0, 0) !== new Date().setHours(0, 0, 0, 0)) {
            // different dates?
            // return new state
            const state = initGame();
            saveState(state);
            return state;
        } else {
            return state;
        }
    } else {
        // no saved state
        const state = initGame();
        saveState(state);
        return state;
    }
}

export { saveState, restoreState }
import React, { useEffect, useState } from 'react';
import './App.css';

import { GameState, GameStatus, getFeedback, LetterStatus, makeGuess, MAX_GUESSES } from './game/game';
import GameRow from './GameRow';
import Keyboard from './Keyboard';
import { restoreState, saveState } from './persist';

const VALID_LETTERS: string[] = [..."abcdefghijklmnopqrstuvwxyz"];

function App() {
  const [currentGuess, setCurrentGuess] = useState<string>("");
  const [guessInvalid, setGuessInvalid] = useState<boolean>(false);
  const [game, setGameState] = useState<GameState>(restoreState());

  saveState(game); // save game state whenever we re-render

  function submitGuess() {
    const guess = currentGuess;

    const newState = makeGuess(game, guess);
    if (!newState) {
      // guess was not valid
      setGuessInvalid(true);
    } else {
      // guess was valid
      setGuessInvalid(false);
      setCurrentGuess("");
      setGameState(newState);
    }
  }

  function onKeyDown(e: KeyboardEvent) {
    const key = e.key.toLowerCase();
    if (VALID_LETTERS.indexOf(key) !== -1 && currentGuess.length < 5) {
      setCurrentGuess(currentGuess + key);
      setGuessInvalid(false);
    } else if (key === "backspace") {
      setCurrentGuess(currentGuess.substring(0, currentGuess.length - 1));
      setGuessInvalid(false);
    } else if (key === "enter") {
      submitGuess();
    }
  }

  // listen for keypresses
  useEffect(() => {
    window.addEventListener("keydown", onKeyDown);
    return () => { window.removeEventListener("keydown", onKeyDown); };
  });

  // index of each row
  const rowIndices = [...Array(MAX_GUESSES).keys()];


  const keyboardColorMap = new Map<string, LetterStatus>();

  // generate keyboard color map
  for (var guess of game.guesses) {
    const feedback = getFeedback(game.solution.word, guess);

    for (const [idx, letterStatus] of feedback.entries()) {
      const letter = guess.charAt(idx);

      if (letterStatus === LetterStatus.Green) {
        keyboardColorMap.set(letter, LetterStatus.Green);
      } else if (letterStatus === LetterStatus.Yellow && keyboardColorMap.get(letter) !== LetterStatus.Green) {
        keyboardColorMap.set(letter, LetterStatus.Yellow)
      } else if (letterStatus === LetterStatus.Gray && keyboardColorMap.get(letter) !== LetterStatus.Yellow && keyboardColorMap.get(letter) !== LetterStatus.Green) {
        keyboardColorMap.set(letter, LetterStatus.Gray);
      }
    }
  }

  return <div className="Game">
    <header>
      <h1>ANNA WORDLE&lt;3</h1>
    </header>
    <div className="alert">
      {
        game.status !== GameStatus.Playing ? (
          game.status === GameStatus.Won ? (
            // won!
            <>
              <h1>you won!</h1>
              <h2>the word was {game.solution.word.toUpperCase()}</h2>
              <h2>{game.solution.reason}</h2>
            </>
          ) : (
            // lost :(
            <>
              <h1>you lost :(</h1>
              <h2>the word was {game.solution.word.toUpperCase()}</h2>
              <h2>{game.solution.reason}</h2>
            </>
          )
        ) : null
      }
    </div>
    <div className="board">
      {
        rowIndices.map((idx) => {
          if (idx < game.guesses.length) {
            return <GameRow key={idx} letters={game.guesses[idx]} feedback={getFeedback(game.solution.word, game.guesses[idx])} />;
          } else if (idx === game.guesses.length) {
            return <GameRow key={idx} letters={currentGuess} feedback={[]} guessInvalid={guessInvalid} />
          } else {
            return <GameRow key={idx} letters="" feedback={[]} />;
          }
        })
      }
    </div>
    <Keyboard keydown={onKeyDown} colorMap={keyboardColorMap} />
  </div>
}

export default App;
import { LetterStatus } from "./game/game";

function Key(props: { display: string, theKey?: string, colorMap: Map<string, LetterStatus>, handleKeyDown: (e: any) => void, special?: boolean }) {
    const { theKey, display, colorMap, special, handleKeyDown } = props;
    const keyVal = theKey ?? display;

    function onClick(e: any) {
        handleKeyDown({ key: keyVal, altKey: false });
    }

    if (!!special) {
        return <button className="Key special" onClick={onClick} data-letterstatus={colorMap.get(keyVal)}> {display}</ button>
    } else {
        return <button className="Key" onClick={onClick} data-letterstatus={colorMap.get(keyVal)}> {display}</ button>
    }

}
function Keyboard(props: { keydown: (e: any) => void, colorMap: Map<string, LetterStatus> }) {
    const { keydown, colorMap } = props;

    return <div className="Keyboard">
        <div className="KeyboardRow">
            <Key display="q" colorMap={colorMap} handleKeyDown={keydown} />
            <Key display="w" colorMap={colorMap} handleKeyDown={keydown} />
            <Key display="e" colorMap={colorMap} handleKeyDown={keydown} />
            <Key display="r" colorMap={colorMap} handleKeyDown={keydown} />
            <Key display="t" colorMap={colorMap} handleKeyDown={keydown} />
            <Key display="y" colorMap={colorMap} handleKeyDown={keydown} />
            <Key display="u" colorMap={colorMap} handleKeyDown={keydown} />
            <Key display="i" colorMap={colorMap} handleKeyDown={keydown} />
            <Key display="o" colorMap={colorMap} handleKeyDown={keydown} />
            <Key display="p" colorMap={colorMap} handleKeyDown={keydown} />
        </div>
        <div className="KeyboardRow">
            <div className="keySpacer" />
            <Key display="a" colorMap={colorMap} handleKeyDown={keydown} />
            <Key display="s" colorMap={colorMap} handleKeyDown={keydown} />
            <Key display="d" colorMap={colorMap} handleKeyDown={keydown} />
            <Key display="f" colorMap={colorMap} handleKeyDown={keydown} />
            <Key display="g" colorMap={colorMap} handleKeyDown={keydown} />
            <Key display="h" colorMap={colorMap} handleKeyDown={keydown} />
            <Key display="j" colorMap={colorMap} handleKeyDown={keydown} />
            <Key display="k" colorMap={colorMap} handleKeyDown={keydown} />
            <Key display="l" colorMap={colorMap} handleKeyDown={keydown} />
            <div className="keySpacer" />
        </div>
        <div className="KeyboardRow">
            <Key display="enter" colorMap={colorMap} theKey="enter" handleKeyDown={keydown} special />
            <Key display="z" colorMap={colorMap} handleKeyDown={keydown} />
            <Key display="x" colorMap={colorMap} handleKeyDown={keydown} />
            <Key display="c" colorMap={colorMap} handleKeyDown={keydown} />
            <Key display="v" colorMap={colorMap} handleKeyDown={keydown} />
            <Key display="b" colorMap={colorMap} handleKeyDown={keydown} />
            <Key display="n" colorMap={colorMap} handleKeyDown={keydown} />
            <Key display="m" colorMap={colorMap} handleKeyDown={keydown} />
            <Key display="⌫" colorMap={colorMap} theKey="backspace" handleKeyDown={keydown} special />
        </div>
    </div>;
}

export default Keyboard;